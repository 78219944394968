(function ($) {

    const autoplayButton = $('.autoplay-button');

    const rectangles = $('#rectangles');
    const textAndButtons = $('.slide-3').find('.slide-3-elements')
    const btnResults = $('.btn-results');
    const btnPhotos = $('.btn-photos');
    const fieldRectangle = $('.field-rectangle');

    const graphDataColumns = $('.graph-data-columns');
    const autoplayButtonContainer =  $('.autoplay-button-container');
    const calendarAnimations =  $('.calendar-animations');
    const fieldRectangles = $('.field-rectangles');
    const slide2MainText = $('.slide-2').find('.main-text');

    const modalWrap = $('.modal-wrap');

    const modalRectangles = $('.modal-rectangles');
    const modalRectanglesClose = modalRectangles.find('.modal-close');
    const rectangleCardContainer = $('.rectangle-card-container')

    const modalResults = $('.modal-results');
    const modalResultsClose = modalResults.find('.modal-close');
    const fieldPhoto = $('.field-photo');
    const dataChart = $('.data-chart');
    const resultsGraph = $('.results-graph');

    const modalPhotos = $('.modal-photos');
    const modalPhotosClose = modalPhotos.find('.modal-close');
    const photoGallery = $('.photo-gallery');

    const showResults = $('.show-results');
    const showTestField = $('.show-test-field');
    const galleryPhoto = $('.photo-gallery-container').find('.photo');

    const fullPageContainer = $('.full-page-container');
    const rotateScreenAlert = $('.rotate-screen-alert');



    // SWIPERS

    const fullpageSwiper = new Swiper(".full-page-container", {
        direction: 'vertical',
        loop: false,
        slidesPerView: 1,
        spaceBetween: 0,
        keyboard: {
            enabled: false,
        },
        mousewheel: {
            releaseOnEdges: false,
            sensitivity: 0.1,
        },
        speed: 1000,
        navigation: {
            nextEl: '.scroll-to-next',
            prevEl: '.scroll-to-previous',
        },
        breakpoints: {
            1040: {
                noSwiping: true,
                allowTouchMove: false,
            },
            400: {
                noSwiping: false,
                allowTouchMove: true,
            }
        }
    })

    const photoGallerySwiper = new Swiper('.photo-gallery-container', {

        slidesPerView: 3.5,
        loop: true,
        spaceBetween: 26,
        navigation: {
            nextEl: '.next-photo',
            prevEl: '.prev-photo',
        },
        breakpoints: {
            1600: {
                slidesPerView: 4.5,
            },
        }
    })

    const rectangleCardSwiper = new Swiper('.rectangle-card-container', {
        slidesPerView: 1,
        spaceBetween: 50,
        loop: true,
        noSwiping: true,
        allowTouchMove: false,
        navigation: {
            nextEl: '.next-card',
            prevEl: '.prev-card',
        },
    })



    // ANIMATIONS

    function animateValue(obj, start, end, duration) {
        let startTimestamp = null;
        const step = (timestamp) => {
            if (!startTimestamp) {
                startTimestamp = timestamp;
            }
            const progress = Math.min((timestamp - startTimestamp) / duration, 1);
            obj.innerHTML = Math.floor(progress * (end - start) + start);
            if (progress < 1) {
                window.requestAnimationFrame(step);
            }
        };
        window.requestAnimationFrame(step);
    }

    function playSlide1Animations(){
        $('.slide-1').removeClass('zoom-in');
        setTimeout(
            function () {
                $('.slide-1').removeClass('bg');
            },
            1300)
        setTimeout(
            function () {
                var mainText = $('.slide-1').find('.main-text');
                mainText.removeClass('hidden');
                $('.line-1').addClass('animate');
            },
            1200)
        setTimeout(
            function () {
                $('.content-top').removeClass('hide');
                $('.content-bottom').removeClass('hide');
            },
            3300)
        setTimeout(
            function () {
                $('.location-pin').addClass('animate');
            },
            3500)
        setTimeout(
            function () {
                $('.autoplay-button ').addClass('show');
            },
            4200)

        setTimeout(
            function () {
                $('.arrow-1').addClass('show');
            },
            5000)
    }

    function playSlide2Animations(){

        setTimeout(
            function () {
                $('.slide-2-text.top-text').removeClass('hide');
            },
            500)
        setTimeout(
            function () {
                slide2MainText.removeClass('hidden');
                $('.line-2').addClass('animate');
            },
            1200)

        setTimeout(function () {
                const obj = document.getElementById("test-date-day");
                const timings = [
                    [8, 30],
                    [1, 7],
                ]
                for (let i = 0; i < timings.length; i++) {
                    if (i > 0) {
                        setTimeout(function () {
                            animateValue(obj, timings[i][0], timings[i][1], 1000);
                        }, 1000)
                    } else {
                        animateValue(obj, timings[i][0], timings[i][1], 1000);
                    }
                }
                Scrambler({
                    text: 'Rugpjūtis',
                    target: '.test-date-month',
                    random: [1000, 2000],
                    speed: 50,
                    afterAll: function () {
                        calendarAnimations.removeClass('to-animate');
                    }
                });
            },
            4500)
        setTimeout(
            function () {
                $('.slide-2-text.bottom-text').removeClass('hide');
            },
            6500)
        setTimeout(
            function () {
                $('.arrow-2').addClass('show');
            },
            7500)
    }

    function playSlide3Animations(){
        var slide3Elements = $('.slide-3-elements');
        var heading = slide3Elements.find('h3');
        var buttons = $('.button-container');

        setTimeout(
            function () {
                heading.addClass('show');
            },
            200)
        setTimeout(
            function () {
                buttons.addClass('show');
            },
            500)
        setTimeout(
            function () {
                $('.field-rectangles').addClass('show');
            },
            1500)
    }

    $(window).on('load', function () {
        playSlide1Animations();
    });

    fullpageSwiper.on('slideChange', function () {

        if (fullpageSwiper.activeIndex === 0 || autoplayButton.hasClass('initialized')) {
            $('.autoplay-button-container').removeClass('hide');
        } else {
            $('.autoplay-button-container').addClass('hide')
        }

        if (fullpageSwiper.activeIndex === 1 && calendarAnimations.hasClass('to-animate')) {
            playSlide2Animations();
        }

        if (fullpageSwiper.activeIndex === 2 && fullpageSwiper.previousIndex === 1) {
            playSlide3Animations();
        }
    })


    // AUTOPLAY

    function cancelAutoplay(){
        location.reload();
    }

    function startAutoplay(){
        var slide3Elements = $('.slide-3-elements');
        var slide3heading = slide3Elements.find('h3');
        var slide3buttons = $('.button-container');

        autoplayButton.addClass('initialized');
        $('body').addClass('disable-pointer');
        calendarAnimations.addClass('to-animate');
        slide2MainText.addClass('hidden');
        $('.slide-2-text.top-text').addClass('hide');
        $('.line-2').removeClass('animate');
        $('.slide-2-text.bottom-text').addClass('hide');
        fieldRectangles.removeClass('show');
        slide3heading.removeClass('show');
        slide3buttons.removeClass('show');
        setTimeout(function () {
            document.getElementById('autoplay-btn').innerHTML = 'Stabdyti';
        }, 200);
        setTimeout(function () {
            $('.arrow-1').click();
            $('.arrow-up').addClass('hide');
            $('.arrow-down').addClass('hide');
        }, 1500);
        setTimeout(function () {
            $('.arrow-2').click();
        }, 10000);
        setTimeout(function () {
            fieldRectangle.click();
            rectangleCardSwiper.slideTo(1, 0, false);
        }, 17000);
        setTimeout(function () {
            rectangleCardSwiper.slideTo(2, 500, false);
        }, 22000);
        setTimeout(function () {
            rectangleCardSwiper.slideTo(3, 500, false);
        }, 27000);
        setTimeout(function () {
            rectangleCardSwiper.slideTo(4, 500, false);
        }, 32000);
        setTimeout(function () {
            rectangleCardSwiper.slideTo(5, 500, false);
        }, 37000);
        setTimeout(function () {
            rectangleCardSwiper.slideTo(6, 500, false);
        }, 42000);
        setTimeout(function () {
            rectangleCardSwiper.slideTo(7, 500, false);
        }, 47000);

        setTimeout(function () {
            showResults.click();
        }, 53000);

        setTimeout(function () {
            modalResultsClose.click();
            setTimeout(function () {
                fullpageSwiper.slideTo(3, 0, false);
            }, 700);
        }, 63000);

        setTimeout(function () {
            $('body').removeClass('disable-pointer');
            $('.arrow-up').removeClass('hide');
            $('.arrow-down').removeClass('hide');
            $('.autoplay-button-container').addClass('hide');
            autoplayButton.removeClass('initialized');
            document.getElementById('autoplay-btn').innerHTML = 'Video peržiūra';
        }, 64000);
    }

    autoplayButton.on('click', function () {
        if (autoplayButton.hasClass('initialized')) {
            cancelAutoplay();
        } else {
            startAutoplay();
        }
    })



    // ROTATE SCREEN FOR MOBILE

    function activateRotateScreenAlert(){
        fullPageContainer.addClass('rotate');
        rotateScreenAlert.addClass('show');
        modalWrap.addClass('hidden');
        autoplayButtonContainer.addClass('locked');
    }

    function deactivateRotateScreenAlert(){
        fullPageContainer.removeClass('rotate');
        rotateScreenAlert.removeClass('show');
        modalWrap.removeClass('hidden');
        autoplayButtonContainer.removeClass('locked');
    }

    let deviceHeight = $(window).outerHeight();
    let deviceWidth = $(window).outerWidth();

    if (deviceHeight >= deviceWidth && deviceWidth < 540) {
        activateRotateScreenAlert();
    }

    $(window).on("resize", function () {
        $.powerTip.hide();
        setTimeout(function () {
            let deviceHeight = $(window).outerHeight();
            let deviceWidth = $(window).outerWidth();

            if (deviceHeight >= deviceWidth && deviceWidth < 540) {
                activateRotateScreenAlert();
            } else {
                deactivateRotateScreenAlert();
            }
        }, 300);
    });



    // MODALS

    function activateModal() {
        rectangles.addClass('hide');
        textAndButtons.addClass('hide');
    }

    function deactivateModal() {
        rectangles.removeClass('hide');
        textAndButtons.removeClass('hide');
    }

    function exitModalPhotos(){
        setTimeout(function () {
            photoGallery.addClass('deactivate');
        }, 200);
        setTimeout(function () {
            deactivateModal();
            photoGallery.removeClass('active');
            modalWrap.removeClass('active');
            photoGallery.removeClass('deactivate');
        }, 900);
    }

    function openRectangleCard(idx){
        modalRectangles.addClass('active');
        rectangleCardSwiper.slideTo(idx + 1, 0, false);
    }

    function openResultsChart(){
        modalResults.addClass('active');
        dataChart.addClass('animate');
        graphDataColumns.addClass('animate');
    }

    fieldRectangle.on('click', function (e) {
        var dataTargetModalId = $(this).data('target-modal-id');
        var slide = rectangleCardContainer.find('.swiper-slide[data-modal-id="' + dataTargetModalId + '"]');
        var slideIdx = slide.data('swiper-slide-index');
        activateModal();
        openRectangleCard(slideIdx);
    })

    modalRectanglesClose.on('click', function () {
        rectangleCardContainer.addClass('deactivate');
        setTimeout(function () {
            rectangleCardContainer.removeClass('deactivate');
            modalRectangles.removeClass('active');
            deactivateModal();
        }, 650);

    })


    btnPhotos.on('click', function (e) {
        setTimeout(function () {
            activateModal();
            modalPhotos.addClass('active');
            photoGallery.addClass('active');
        }, 200);
    })

    modalPhotosClose.on('click', function () {
        exitModalPhotos();
    })

    showTestField.on('click', function () {
        exitModalPhotos();
    })

    galleryPhoto.on('click', function (e) {
        var dataTargetModalId = $(this).data('target-modal-id');
        var slide = rectangleCardContainer.find('.swiper-slide[data-modal-id="' + dataTargetModalId + '"]');
        var slideIdx = slide.data('swiper-slide-index');
        setTimeout(function () {
            photoGallery.addClass('deactivate');
        }, 200);
        setTimeout(function () {
            modalPhotos.removeClass('active');
            openRectangleCard(slideIdx);
        }, 1000);
    })

    showResults.on('click', function (e) {
        setTimeout(function () {
            photoGallery.addClass('deactivate');
        }, 200);
        setTimeout(function () {
            photoGallery.removeClass('active');
            photoGallery.removeClass('deactivate');
            modalWrap.removeClass('active');
            openResultsChart();
        }, 1000);
    })

    btnResults.on('click', function (e) {
        setTimeout(function () {
            activateModal();
            openResultsChart()
        }, 100);
    })

    fieldPhoto.on('click', function (e) {
        var dataTargetModalId = $(this).data('target-modal-id');
        var slide = rectangleCardContainer.find('.swiper-slide[data-modal-id="' + dataTargetModalId + '"]');
        var slideIdx = slide.data('swiper-slide-index');
        setTimeout(function () {
            resultsGraph.addClass('deactivate');
        }, 200);
        setTimeout(function () {
            dataChart.removeClass('animate');
            graphDataColumns.removeClass('animate');
        }, 600);
        setTimeout(function () {
            modalResults.removeClass('active');
            resultsGraph.removeClass('deactivate');
            openRectangleCard(slideIdx);
        }, 1000);
    })

    modalResultsClose.on('click', function () {
        setTimeout(function () {
            resultsGraph.addClass('deactivate');
        }, 200);
        setTimeout(function () {
            dataChart.removeClass('animate');
            graphDataColumns.remove('animate');
        }, 600);
        setTimeout(function () {
            deactivateModal();
            modalWrap.removeClass('active');
            resultsGraph.removeClass('deactivate');
        }, 900);
    })



    //    POWERTIP

    var productToolTip = $('.graph-product-tooltip');

    productToolTip.each(function (index, el) {
        $(el).powerTip({
            placement: 'ne',
            smartPlacement: false,
            followMouse: false,
            mouseOnToPopup: true,
        });
    });

})(jQuery);